import { lazy, Suspense } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import SuperAdminLayout from '../layouts/super-admin/dashboard';
import AuthGuard from '../guards/AuthGuard';
import SuperAdminGuard from '../guards/SuperAdminGuard';
import { SUPER_ADMIN_DASHBOARD } from '../config';
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { pathname } = useLocation();

    return (
        <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/super-admin/dashboard')} />}>
            <Component {...props} />
        </Suspense>
    );
};

const Dashboard = Loadable(lazy(() => import('../pages/users/super-admin/dashboard/Dashboard')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const Roles = Loadable(lazy(() => import('../pages/user-management/Role/Roles')));
const RolePermission = Loadable(lazy(() => import('../pages/user-management/Role/RolePermission')));
const RoleOperation = Loadable(lazy(() => import('../pages/user-management/Role/RoleOperation')));
const Employees = Loadable(lazy(() => import('../pages/user-management/Employee/Employees')));
const EmployeeOperation = Loadable(lazy(() => import('../pages/user-management/Employee/EmployeeOperation')));

const Users = Loadable(lazy(() => import('../pages/user-management/User/SuperAdmin/Users')));
const UserOperation = Loadable(lazy(() => import('../pages/user-management/User/SuperAdmin/UserOperation')));

const UserAccount = Loadable(lazy(() => import('../pages/profile/UserAccount')));
const Languages = Loadable(lazy(() => import('../pages/masters/Language/Languages')));
const LanguageOperation = Loadable(lazy(() => import('../pages/masters/Language/LanguageOperation')));
const Faqs = Loadable(lazy(() => import('../pages/masters/FAQ/FAQs')));
const FaqOperation = Loadable(lazy(() => import('../pages/masters/FAQ/FAQOperation')));
const PageContent = Loadable(lazy(() => import('../pages/masters/PageContent/PageContents')));
const PageContentOperation = Loadable(lazy(() => import('../pages/masters/PageContent/PageContentOperation')));
const GlobalSettings = Loadable(lazy(() => import('../pages/masters/GlobalSettings/GlobalSettings')));
const GlobalSettingsOperation = Loadable(lazy(() => import('../pages/masters/GlobalSettings/GlobalSettingsOperation')));

// Event Types
const EventTypes = Loadable(lazy(() => import('../pages/masters/EventType/EventTypes')));
const EventTypeOperation = Loadable(lazy(() => import('../pages/masters/EventType/EventTypeOperation')));

// Styles
const Styles = Loadable(lazy(() => import('../pages/masters/Style/Styles')));

// Services
const Services = Loadable(lazy(() => import('../pages/masters/Services/Services')));
const ServiceOperation = Loadable(lazy(() => import('../pages/masters/Services/ServiceOperation')));
const ServicePriceTiers = Loadable(lazy(() => import('../pages/masters/Services/PriceTier/PriceTiers')));
const ServicePriceTierOperation = Loadable(lazy(() => import('../pages/masters/Services/PriceTier/PriceTierOperation')));
const ServiceGuestNoCosts = Loadable(lazy(() => import('../pages/masters/Services/GuestNoCost/GuestNoCosts')));
const ServiceGuestNoCostOperation = Loadable(
    lazy(() => import('../pages/masters/Services/GuestNoCost/GuestNoCostOperation'))
);

// Addons
const Addons = Loadable(lazy(() => import('../pages/masters/Addons/Addons')));
const AddonsOperation = Loadable(lazy(() => import('../pages/masters/Addons/AddonOperation')));
const AddonPriceTiers = Loadable(lazy(() => import('../pages/masters/Addons/PriceTier/PriceTiers')));
const AddonPriceTierOperation = Loadable(lazy(() => import('../pages/masters/Addons/PriceTier/PriceTierOperation')));
const AddonGuestNoCosts = Loadable(lazy(() => import('../pages/masters/Addons/GuestNoCost/GuestNoCosts')));
const AddonGuestNoCostOperation = Loadable(lazy(() => import('../pages/masters/Addons/GuestNoCost/GuestNoCostOperation')));

// Price Tiers
const PriceTiers = Loadable(lazy(() => import('../pages/masters/PriceTier/PriceTiers')));
const PriceTierOperation = Loadable(lazy(() => import('../pages/masters/PriceTier/PriceTierOperation')));

const Countries = Loadable(lazy(() => import('../pages/masters/Country/Countries')));
const CountryOperation = Loadable(lazy(() => import('../pages/masters/Country/CountryOperation')));

const States = Loadable(lazy(() => import('../pages/masters/State/States')));
const StateOperation = Loadable(lazy(() => import('../pages/masters/State/StateOperation')));

const Cities = Loadable(lazy(() => import('../pages/masters/City/Cities')));
const CityOperation = Loadable(lazy(() => import('../pages/masters/City/CityOperation')));


const GuestRange = Loadable(lazy(() => import('../pages/masters/GuestRange/GuestRange')));
const GuestRangeOperation = Loadable(lazy(() => import('../pages/masters/GuestRange/GuestRangeOperation')));
// Duration Range
const DurationRange = Loadable(lazy(() => import('../pages/masters/DurationRange/DurationRange')));
const DurationRangeOperation = Loadable(lazy(() => import('../pages/masters/DurationRange/DurationRangeOperation')));

const Testimonial = Loadable(lazy(() => import('../pages/masters/Testimonial/Testimonial')));
const TestimonialOperation = Loadable(lazy(() => import('../pages/masters/Testimonial/TestimonialOperation')));

const Bookings = Loadable(lazy(() => import('../pages/masters/Bookings/Bookings')));
const BookingOperation = Loadable(lazy(() => import('../pages/masters/Bookings/BookingOperation')));

const SupportCategories = Loadable(lazy(() => import('../pages/masters/SupportCategory/SupportCategories')));
const SupportCategoryOperation = Loadable(
    lazy(() => import('../pages/masters/SupportCategory/SupportCategoryOperation'))
);

const Support = Loadable(lazy(() => import('../pages/masters/Support/Support')));
const SupportOperation = Loadable(lazy(() => import('../pages/masters/Support/SupportOperation')));

const Packages = Loadable(lazy(() => import('../pages/masters/Packages/Packages')));
const PackagesOperation = Loadable(lazy(() => import('../pages/masters/Packages/PackagesOperation')));
const AddonsPackages = Loadable(lazy(() => import('../pages/masters/Packages/AddonsPackages/AddonsPackages')));
const AddonsPackagesOperation = Loadable(
    lazy(() => import('../pages/masters/Packages/AddonsPackages/AddonsPackagesOperation'))
);
const Blogs = Loadable(lazy(() => import('../pages/masters/Blogs/Blogs')));
const BlogOperation = Loadable(lazy(() => import('../pages/masters/Blogs/BlogsOperation')));

// Deliverables
const Deliverables = Loadable(lazy(() => import('../pages/masters/Deliverable/Deliverables')));
const DeliverableOperation = Loadable(lazy(() => import('../pages/masters/Deliverable/DeliverableOperation')));

const SuperAdminRoutes = {
    path: '/super-admin',
    element: (
        <AuthGuard>
            <SuperAdminGuard>
                <SuperAdminLayout />
            </SuperAdminGuard>
        </AuthGuard>
    ),
    children: [
        { element: <Navigate to={SUPER_ADMIN_DASHBOARD} replace />, index: true },
        { path: 'dashboard', element: <Dashboard /> },
        {
            path: 'profile',
            children: [
                { path: 'edit-profile', element: <UserAccount /> },
                { path: 'reset-password', element: <ResetPassword /> },
            ],
        },
        {
            path: 'user-management',
            children: [
                { path: 'roles', element: <Roles /> },
                { path: 'role/:id/permission', element: <RolePermission /> },
                { path: 'role/add', element: <RoleOperation /> },
                { path: 'role/:id/edit', element: <RoleOperation /> },
                { path: 'role/:id/view', element: <RoleOperation /> },
                { path: 'users', element: <Users /> },
                { path: 'user/add', element: <UserOperation /> },
                { path: 'user/:id/edit', element: <UserOperation /> },
                { path: 'user/:id/view', element: <UserOperation /> },
                // { path: 'user-approval', element: <UserApproval /> },
                // { path: 'user-approval/:id/edit', element: <UserApprovalOperation /> },
            ],
        },
        {
            path: 'master',
            children: [
                { path: 'languages', element: <Languages /> },
                { path: 'language/add', element: <LanguageOperation /> },
                { path: 'language/:id/edit', element: <LanguageOperation /> },
                { path: 'language/:id/view', element: <LanguageOperation /> },
                { path: 'faqs', element: <Faqs /> },
                { path: 'faqs/add', element: <FaqOperation /> },
                { path: 'faqs/:id/edit', element: <FaqOperation /> },
                { path: 'faqs/:id/view', element: <FaqOperation /> },
                { path: 'event-types', element: <EventTypes /> },
                { path: 'event-types/add', element: <EventTypeOperation /> },
                { path: 'event-types/:id/edit', element: <EventTypeOperation /> },
                { path: 'event-types/:id/view', element: <EventTypeOperation /> },
                { path: 'event-types/:id/images', element: <EventTypeOperation /> },
                { path: 'addons', element: <Addons /> },
                { path: 'addons/add', element: <AddonsOperation /> },
                { path: 'addons/:id/edit', element: <AddonsOperation /> },
                { path: 'addons/:id/view', element: <AddonsOperation /> },
                { path: 'addons/:addonId/price-tiers', element: <AddonPriceTiers /> },
                { path: 'addons/:addonId/price-tiers/add', element: <AddonPriceTierOperation /> },
                { path: 'addons/:addonId/price-tiers/:id/edit', element: <AddonPriceTierOperation /> },
                { path: 'addons/:addonId/price-tiers/:id/view', element: <AddonPriceTierOperation /> },
                { path: 'addons/:addonId/guest-no-cost', element: <AddonGuestNoCosts /> },
                { path: 'addons/:addonId/guest-no-cost/add', element: <AddonGuestNoCostOperation /> },
                { path: 'addons/:addonId/guest-no-cost/:id/edit', element: <AddonGuestNoCostOperation /> },
                { path: 'addons/:addonId/guest-no-cost/:id/view', element: <AddonGuestNoCostOperation /> },

                { path: 'services', element: <Services /> },
                { path: 'services/add', element: <ServiceOperation /> },
                { path: 'services/:id/edit', element: <ServiceOperation /> },
                { path: 'services/:id/view', element: <ServiceOperation /> },
                { path: 'services/:addonId/price-tiers', element: <ServicePriceTiers /> },
                { path: 'services/:addonId/price-tiers/add', element: <ServicePriceTierOperation /> },
                { path: 'services/:addonId/price-tiers/:id/edit', element: <ServicePriceTierOperation /> },
                { path: 'services/:addonId/price-tiers/:id/view', element: <ServicePriceTierOperation /> },
                { path: 'services/:addonId/guest-no-cost', element: <ServiceGuestNoCosts /> },
                { path: 'services/:addonId/guest-no-cost/add', element: <ServiceGuestNoCostOperation /> },
                { path: 'services/:addonId/guest-no-cost/:id/edit', element: <ServiceGuestNoCostOperation /> },
                { path: 'services/:addonId/guest-no-cost/:id/view', element: <ServiceGuestNoCostOperation /> },

                { path: 'price-tiers', element: <PriceTiers /> },
                { path: 'price-tiers/add', element: <PriceTierOperation /> },
                { path: 'price-tiers/:id/edit', element: <PriceTierOperation /> },
                { path: 'price-tiers/:id/view', element: <PriceTierOperation /> },

                { path: 'styles', element: <Styles /> },


                { path: 'countries', element: <Countries /> },
                { path: 'countries/add', element: <CountryOperation /> },
                { path: 'countries/:id/edit', element: <CountryOperation /> },
                { path: 'countries/:id/view', element: <CountryOperation /> },

                { path: 'states', element: <States /> },
                { path: 'states/add', element: <StateOperation /> },
                { path: 'states/:id/edit', element: <StateOperation /> },
                { path: 'states/:id/view', element: <StateOperation /> },

                { path: 'cities', element: <Cities /> },
                { path: 'cities/add', element: <CityOperation /> },
                { path: 'cities/:id/edit', element: <CityOperation /> },
                { path: 'cities/:id/view', element: <CityOperation /> },

                { path: 'bookings', element: <Bookings /> },
                { path: 'bookings/add', element: <BookingOperation /> },
                { path: 'bookings/:id/edit', element: <BookingOperation /> },
                { path: 'bookings/:id/view', element: <BookingOperation /> },

                { path: 'guest-range', element: <GuestRange /> },
                { path: 'guest-range/add', element: <GuestRangeOperation /> },
                { path: 'guest-range/:id/edit', element: <GuestRangeOperation /> },
                { path: 'guest-range/:id/view', element: <GuestRangeOperation /> },

                { path: 'duration-range', element: <DurationRange /> },
                { path: 'duration-range/add', element: <DurationRangeOperation /> },
                { path: 'duration-range/:id/edit', element: <DurationRangeOperation /> },
                { path: 'duration-range/:id/view', element: <DurationRangeOperation /> },

                { path: 'testimonials', element: <Testimonial /> },
                { path: 'testimonials/add', element: <TestimonialOperation /> },
                { path: 'testimonials/:id/edit', element: <TestimonialOperation /> },
                { path: 'testimonials/:id/view', element: <TestimonialOperation /> },

                { path: 'support-categories', element: <SupportCategories /> },
                { path: 'support-categories/add', element: <SupportCategoryOperation /> },
                { path: 'support-categories/:id/edit', element: <SupportCategoryOperation /> },
                { path: 'support-categories/:id/view', element: <SupportCategoryOperation /> },

                { path: 'supports', element: <Support /> },
                { path: 'supports/add', element: <SupportOperation /> },
                { path: 'supports/:id/edit', element: <SupportOperation /> },
                { path: 'supports/:id/view', element: <SupportOperation /> },

                { path: 'packages', element: <Packages /> },
                { path: 'packages/add', element: <PackagesOperation /> },
                { path: 'packages/:id/edit', element: <PackagesOperation /> },
                { path: 'packages/:id/view', element: <PackagesOperation /> },
                { path: 'packages/:id/addon', element: <AddonsPackages /> },
                { path: 'packages/:id/addon/:addonId/add', element: <AddonsPackagesOperation /> },
                { path: 'packages/:id/addon/:addonId/edit', element: <AddonsPackagesOperation /> },
                { path: 'packages/:id/addon/:addonId/view', element: <AddonsPackagesOperation /> },

                { path: 'deliverables', element: <Deliverables /> },
                { path: 'deliverables/add', element: <DeliverableOperation /> },
                { path: 'deliverables/:id/edit', element: <DeliverableOperation /> },
                { path: 'deliverables/:id/view', element: <DeliverableOperation /> },

                { path: 'blogs', element: <Blogs /> },
                { path: 'blogs/add', element: <BlogOperation /> },
                { path: 'blogs/:id/edit', element: <BlogOperation /> },
                { path: 'blogs/:id/view', element: <BlogOperation /> },
            ],
        },
        {
            path: 'page-content',
            children: [
                { path: '', element: <PageContent /> },
                { path: 'add', element: <PageContentOperation /> },
                { path: ':id/edit', element: <PageContentOperation /> },
                { path: ':id/view', element: <PageContentOperation /> },
            ],
        },
        {
            path: 'global-settings',
            children: [
                { path: '', element: <GlobalSettings /> },
                { path: 'add', element: <GlobalSettingsOperation /> },
                { path: ':id/edit', element: <GlobalSettingsOperation /> },
                { path: ':id/view', element: <GlobalSettingsOperation /> },
            ],
        },
    ],
};

export default SuperAdminRoutes;
