import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers';
import { styled } from '@mui/material';
import dayjs from 'dayjs';

const StyledDatePicker = styled(DatePicker)(() => ({
    '& .MuiInputLabel-asterisk': {
        color: 'red',
    },
}));

RHFDateField.propTypes = {
    name: PropTypes.string,
    disabled: PropTypes.bool,
    defaultValue: PropTypes.string,
};

export default function RHFDateField({ name, disabled, defaultValue, ...other }) {
    const { control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, onBlur }, fieldState: { error } }) => {
                return (
                    <StyledDatePicker
                        fullWidth
                        slotProps={{
                            textField: {
                                variant: 'outlined',
                                error: !!error,
                                helperText: error?.message,
                            },
                        }}
                        format="YYYY/MM/DD"
                        label="Date"
                        value={defaultValue ? dayjs(defaultValue) : null}
                        onChange={(date) => {
                            onChange(date ? dayjs(date).format('YYYY/MM/DD') : '');
                        }}
                        onBlur={onBlur}
                        disabled={disabled}
                        minDate={dayjs('1000/01/01')}
                        maxDate={dayjs('2400/12/31')}
                        sx={{ backgroundColor: disabled ? 'lightgrey' : 'inherit', width: '100%' }}
                        {...other}
                    />
                );
            }}
        />
    );
}
